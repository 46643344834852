<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :total="items.results"
  :saveReport="getDeptorGroupsReportExel"
  isNotMounted
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDebtor',
  data () {
    return {
      headers: [
        { value: 'group', text: this.$t('group'), title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') }, sortable: false },
        { value: 'sum_f1f2', text: this.$t('coming'), align: 'center', total: { global: 'global_form2_sum', page: 'page_form2_sum', align: 'center' }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'page_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({
            name: 'debtor-report-group',
            params: { ...this.$route.params, group: item.group },
            query: { ...this.$route.query } }),
          target: '_blank',
          color: 'blue',
          name: 'mdi-information-outline',
          tooltip: 'tooltip.info',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listDebtorGroups,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListDebtorGroups', 'getDeptorGroupsReportExel']),
    async getData (params) {
      this.getListDebtorGroups({ params })
    }

  }
}
</script>
